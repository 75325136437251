import { graphql, useStaticQuery } from 'gatsby';
import { Col } from 'react-awesome-styled-grid';

import Button from '../Button';
import CaptionedTitle from '../CaptionedTitle';
import CardsRow from '../CardsRow';
import Markdown from '../Markdown';
import { SectionRow } from '../Rows';
import { BlockRow, OrganismRow } from '../Rows';

const TechnologyPageYouOwnSwapboxSection = () => {
  const { data } = useStaticQuery(query);
  const {
    youOwnSwapboxSection: { title, text, button, cards }
  } = data.edges[0].node;

  return (
    <SectionRow>
      <Col>
        <CaptionedTitle as="h2">{title}</CaptionedTitle>
        <OrganismRow>
          <Col lg={6}>
            <Markdown content={text} />
          </Col>
        </OrganismRow>
        <BlockRow>
          <Col lg={2}>
            <Button to={button.url}>{button.text}</Button>
          </Col>
        </BlockRow>
        <CardsRow cards={cards} />
      </Col>
    </SectionRow>
  );
};

const query = graphql`
  query {
    data: allTechnologyPageYaml {
      edges {
        node {
          youOwnSwapboxSection {
            title
            text
            button {
              text
              url
            }
            cards {
              title
              description
              icon
              link {
                text
                url
              }
            }
          }
        }
      }
    }
  }
`;

export default TechnologyPageYouOwnSwapboxSection;
