import { FC } from 'react';
import { Col } from 'react-awesome-styled-grid';
import styled from 'styled-components';

import Heading from './Heading';
import Markdown from './Markdown';
import { BlockRow } from './Rows';

interface SimpleInnerSubsection {
  title: string;
  text: string;
}

const SimpleInnerSubsection: FC<SimpleInnerSubsection> = ({ children, title, text }) => {
  return (
    <BlockRow>
      <Col>
        <Heading element="h3" styledAs="h4">
          {title}
        </Heading>
        <MarkdownStyled content={text} />
        {children}
      </Col>
    </BlockRow>
  );
};

const MarkdownStyled = styled(Markdown)`
  margin-top: var(--spacing-2);
`;

export default SimpleInnerSubsection;
