import { graphql, useStaticQuery } from 'gatsby';
import { useEffect, useState } from 'react';
import { Col } from 'react-awesome-styled-grid';
import styled from 'styled-components';

import useWindowSize from '../../hooks/useWindowSize';
import grid from '../../styles/grid';
import Button from '../Button';
import Diagram from '../Diagram';
import Heading from '../Heading';
import { BlockRow, OrganismRow, SectionInnerRow, SectionRow } from '../Rows';
import SideMenu from '../SideMenu';
import SimpleInnerSection from '../SimpleInnerSection';
import SimpleInnerSubsection from '../SimpleInnerSubsection';
import SimpleLink from '../SimpleLink';
import { TableRow } from '../Table';

const MainSections = () => {
  const { data } = useStaticQuery(query);
  const windowSize = useWindowSize();
  const {
    mainSections: {
      transactionsSection,
      liquiditySection,
      stablecoinsSection,
      ownershipSection,
      // dappAdminPanelSection,
      // authenticitySection,
      underlyingTechSection,
      ctaSection
    }
  } = data.edges[0].node;

  const [activeMenuItem, setActiveMenuItem] = useState(0);
  const toc = [
    transactionsSection,
    liquiditySection,
    stablecoinsSection,
    ownershipSection,
    // dappAdminPanelSection,
    // authenticitySection,
    underlyingTechSection
  ].map(({ tocTitle }) => ({ title: tocTitle }));

  const tocIds = toc.map(({ title }) => title.toLocaleLowerCase().replace(/\s/g, '-'));

  const scrollToPageSection = (menuItemIndex: number) => {
    setActiveMenuItem(menuItemIndex);
    const id = tocIds[menuItemIndex];
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  const isNotMobile = windowSize.width !== undefined && windowSize.width > grid.breakpoints.sm * 16;

  useEffect(() => {
    if (isNotMobile) {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          document
            .querySelector(`#menu-item-${entry.target.id}`)
            ?.classList.toggle('active', entry.intersectionRatio > 0);
        });
      });
      document.querySelectorAll('.toc-anchor').forEach((element) => observer.observe(element));
    }
  }, [isNotMobile, tocIds]);

  return (
    <SectionRow>
      <Col md={2} lg={3}>
        <SideMenuSticky
          menuItems={toc}
          onMenuItemClick={scrollToPageSection}
          activeMenuItem={activeMenuItem}
          disableMenuItemActivation={isNotMobile}
        />
      </Col>
      <Col md={6} lg={6}>
        <SectionInnerRow id={tocIds[0]} className="toc-anchor">
          <Col>
            <Heading2 element="h2" styledAs="h3">
              {transactionsSection.title}
            </Heading2>
            <SimpleInnerSubsection
              title={transactionsSection.scalability.title}
              text={transactionsSection.scalability.text}
            >
              <TableRow
                columns={transactionsSection.scalability.table.columns}
                rows={transactionsSection.scalability.table.rows}
              />
              <BlockRow>
                <Col>
                  <SimpleLink to={transactionsSection.scalability.link.url}>
                    {transactionsSection.scalability.link.text}
                  </SimpleLink>
                </Col>
              </BlockRow>
            </SimpleInnerSubsection>

            <SimpleInnerSubsection title={transactionsSection.cashIn.title} text={transactionsSection.cashIn.text}>
              <OrganismRow>
                <Col>
                  <Diagram {...transactionsSection.cashIn.diagram} maxImageWidth="419px" />
                </Col>
              </OrganismRow>
            </SimpleInnerSubsection>

            <SimpleInnerSubsection title={transactionsSection.cashOut.title} text={transactionsSection.cashOut.text}>
              <OrganismRow>
                <Col>
                  <Diagram {...transactionsSection.cashOut.diagram} maxImageWidth="419px" />
                </Col>
              </OrganismRow>
            </SimpleInnerSubsection>
          </Col>
        </SectionInnerRow>
        <SimpleInnerSection
          id={tocIds[1]}
          className="toc-anchor"
          title={liquiditySection.title}
          text={liquiditySection.text}
        />
        <SimpleInnerSection
          id={tocIds[2]}
          className="toc-anchor"
          title={stablecoinsSection.title}
          text={stablecoinsSection.text}
          diagrams={[stablecoinsSection.diagram]}
          maxImageWidths={['180px']}
        />
        <SimpleInnerSection
          id={tocIds[3]}
          className="toc-anchor"
          title={ownershipSection.title}
          text={ownershipSection.text}
          diagrams={ownershipSection.diagrams}
          maxImageWidths={['232px', '288px', '319px']}
        />
        {/*<SimpleInnerSection
          id={tocIds[4]}
          className="toc-anchor"
          title={dappAdminPanelSection.title}
          text={dappAdminPanelSection.text}
        >
          <BlockRow>
            <Col lg={6}>
              <Button primary to={dappAdminPanelSection.button.url} IconComponent={ArrowIcon}>
                {dappAdminPanelSection.button.text}
              </Button>
              {dappAdminPanelSection.links.map(({ text, url }: { text: string; url: string }) => (
                <OrganismRow key={text}>
                  <Col>
                    <SimpleLink to={url}>{text}</SimpleLink>
                  </Col>
                </OrganismRow>
              ))}
            </Col>
          </BlockRow>
          <BlockRow>
            <Col>
              <GatsbyImage
                className="full-width-mobile"
                image={dappAdminPanelSection.image.childImageSharp.gatsbyImageData}
                alt={dappAdminPanelSection.imageAltText}
              />
            </Col>
          </BlockRow>
          <BlockRow>
            {dappAdminPanelSection.textGridItems.map(({ title, text }: { title: string; text: string }) => (
              <ColStyled lg={6} key={title}>
                <Heading element="h3" styledAs="h4">
                  {title}
                </Heading>
                <MarkdownStyled content={text} />
              </ColStyled>
            ))}
          </BlockRow>
        </SimpleInnerSection>*/}
        {/*<SimpleInnerSection
          id={tocIds[5]}
          className="toc-anchor"
          title={authenticitySection.title}
          text={authenticitySection.text}
          diagrams={authenticitySection.diagrams}
          maxImageWidths={['360px', '177px']}
        />*/}
        <SimpleInnerSection
          id={tocIds[6]}
          className="toc-anchor"
          title={underlyingTechSection.title}
          text={underlyingTechSection.text}
          diagrams={[underlyingTechSection.diagram]}
          maxImageWidths={['531px']}
        />
        <SectionRow justify={'center'}>
          <Col lg={4}>
            <Button primary to={ctaSection.button.url}>
              {ctaSection.button.text}
            </Button>
          </Col>
        </SectionRow>
      </Col>
    </SectionRow>
  );
};

const Heading2 = styled(Heading)`
  margin: 0;
  border-bottom: 1px solid ${({ theme }) => theme.text.primary};
`;

// const ColStyled = styled(Col)`
//   margin-bottom: var(--spacing-4);
// `;

const SideMenuSticky = styled(SideMenu)`
  position: sticky;
  top: var(--spacing-7);
`;

// const MarkdownStyled = styled(Markdown)`
//   margin-top: var(--spacing-2);
// `;

const query = graphql`
  query {
    data: allTechnologyPageYaml {
      edges {
        node {
          mainSections {
            transactionsSection {
              tocTitle
              title
              scalability {
                title
                text
                table {
                  columns
                  rows {
                    row
                  }
                }
                link {
                  text
                  url
                }
              }
              cashIn {
                title
                text
                diagram {
                  image {
                    publicURL
                  }
                  imageAltText
                  caption
                  topCaption
                }
              }
              cashOut {
                title
                text
                diagram {
                  image {
                    publicURL
                  }
                  imageAltText
                  caption
                  topCaption
                }
              }
            }
            liquiditySection {
              tocTitle
              title
              text
            }
            stablecoinsSection {
              tocTitle
              title
              text
              diagram {
                image {
                  publicURL
                }
                imageAltText
                caption
                topCaption
              }
            }
            ownershipSection {
              tocTitle
              title
              text
              diagrams {
                image {
                  publicURL
                }
                imageAltText
                caption
                topCaption
              }
            }
            underlyingTechSection {
              tocTitle
              title
              text
              diagram {
                image {
                  publicURL
                }
                imageAltText
                caption
                topCaption
              }
            }
            ctaSection {
              button {
                text
                url
              }
            }
          }
        }
      }
    }
  }
`;

export default MainSections;
