import { graphql, useStaticQuery } from 'gatsby';
import { Container } from 'react-awesome-styled-grid';
import { ThemeProvider } from 'styled-components';

import Page from '../components/Page';
import TechnologyPageHeader from '../components/technology-page/TechnologyPageHeader';
import TechnologyPageMainSections from '../components/technology-page/TechnologyPageMainSections';
import TechnologyPageYouOwnSwapboxSection from '../components/technology-page/TechnologyPageYouOwnSwapboxSection';
import ThemedBackground from '../components/ThemedBackground';
import { darkTheme, lightTheme } from '../styles/themes';

const TechnologyPage = () => {
  const { data } = useStaticQuery(query);
  const { seo } = data.edges[0].node;

  return (
    <Page seoTitle={seo.title} seoDescription={seo.descripton}>
      <ThemeProvider theme={lightTheme}>
        <TechnologyPageHeader />
      </ThemeProvider>
      <ThemeProvider theme={darkTheme}>
        <ThemedBackground pt pb>
          <Container>
            <TechnologyPageMainSections />
          </Container>
        </ThemedBackground>
      </ThemeProvider>
      <ThemeProvider theme={lightTheme}>
        <ThemedBackground pt pb>
          <Container>
            <TechnologyPageYouOwnSwapboxSection />
          </Container>
        </ThemedBackground>
      </ThemeProvider>
    </Page>
  );
};

const query = graphql`
  query {
    data: allTechnologyPageYaml {
      edges {
        node {
          seo {
            title
            description
          }
        }
      }
    }
  }
`;

export default TechnologyPage;
