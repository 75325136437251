import { FC } from 'react';
import { Col } from 'react-awesome-styled-grid';
import styled from 'styled-components';

import deviceBreakPoints from '../styles/breakpoints';
import { BlockRow } from './Rows';

interface TableProps {
  columns: string[];
  rows: {
    row: string[];
  }[];
  className?: string;
}

let Table: FC<TableProps> = ({ columns, rows, className }) => (
  <table className={className}>
    <thead>
      <tr>
        <th></th>
        {columns.map((column) => (
          <th key={column}>{column}</th>
        ))}
      </tr>
    </thead>
    <tbody>
      {rows.map((row) => (
        <tr key={row.row[0]}>
          {row.row.map((cell: string, index: number) => (
            <td key={`${cell}-${index}`}>{cell}</td>
          ))}
        </tr>
      ))}
    </tbody>
  </table>
);

export const TableRow: FC<TableProps> = ({ columns, rows, className }) => (
  <BlockRowStyled className={className}>
    <Col>
      <Table columns={columns} rows={rows} />
    </Col>
  </BlockRowStyled>
);

Table = styled(Table)`
  border-collapse: collapse;

  th,
  td:first-child {
    color: ${({ theme }) => theme.text.secondary};
    font-weight: var(--font-weight-regular);
  }

  th,
  td {
    padding: 24px;
    padding-left: 0;
    text-align: left;
    white-space: nowrap;
  }

  tr {
    border-bottom: 1px solid ${({ theme }) => theme.text.secondary};
  }

  thead tr {
    border-bottom: 1px solid ${({ theme }) => theme.text.primary};
  }
`;

const BlockRowStyled = styled(BlockRow)`
  @media ${deviceBreakPoints.mobile} {
    overflow-x: scroll;
    width: 100vw;
    margin-left: calc(-50vw + 50%);
    padding-left: calc(((100vw - 100%) / 2));
  }
`;

export default Table;
