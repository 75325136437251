import { FC } from 'react';
import styled from 'styled-components';

interface HeaderComparisonListProps {
  title: string;
  listItems: string[];
  className?: string;
}

const HeaderComparisonList: FC<HeaderComparisonListProps> = ({ className, title, listItems }) => {
  return (
    <div className={className}>
      <Title>{title}</Title>
      {listItems.map((listItem) => (
        <ListItem key={listItem}>{listItem}</ListItem>
      ))}
    </div>
  );
};

const Title = styled.div`
  color: ${({ theme }) => theme.text.secondary};
`;

const ListItem = styled.div`
  margin-top: var(--spacing-2);
`;

export default HeaderComparisonList;
