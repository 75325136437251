import { graphql, useStaticQuery } from 'gatsby';
import { FC } from 'react';
import { Col, Row } from 'react-awesome-styled-grid';
import styled from 'styled-components';

import CaptionedTitle from '../../components/CaptionedTitle';
import Markdown from '../../components/Markdown';
import PageHeader from '../../components/PageHeader';
import { BlockRow } from '../../components/Rows';
import ATMOpenImage from '../../images/svgs/atm-open2.svg';
import deviceBreakPoints from '../../styles/breakpoints';
import TechnologyPageHeaderComparisonList from './TechnologyPageHeaderComparisonList';

let TechnologyPageHeader: FC<{ className?: string }> = ({ className }) => {
  const { data } = useStaticQuery(query);
  const {
    header: { title, description, caption, comparisonLists }
  } = data.edges[0].node;

  return (
    <PageHeader className={className}>
      <Row>
        <Col lg={6}>
          <CaptionedTitle as="h2" caption={caption}>
            {title}
          </CaptionedTitle>
          <BlockRow>
            <Col lg={8}>
              <Markdown content={description} />
              <BlockRow>
                <Col xs={2}>
                  <TechnologyPageHeaderComparisonList
                    title={comparisonLists.swapbox.title}
                    listItems={comparisonLists.swapbox.properties}
                  />
                </Col>
                <Col xs={2}>
                  <TechnologyPageHeaderComparisonList
                    title={comparisonLists.otherCryptoATM.title}
                    listItems={comparisonLists.otherCryptoATM.properties}
                  />
                </Col>
              </BlockRow>
            </Col>
          </BlockRow>
        </Col>
        <ColImage lg={6}>
          <ATMOpenImageStyled />
        </ColImage>
      </Row>
    </PageHeader>
  );
};

TechnologyPageHeader = styled(TechnologyPageHeader)`
  overflow: hidden;
`;

const ATMOpenImageStyled = styled(ATMOpenImage)`
  position: absolute;
  width: 916px;
  height: auto;
  margin-top: calc(var(--spacing-5) * -1);

  @media ${deviceBreakPoints.desktop} {
    position: relative;
    margin-top: var(--spacing-5);
    width: calc(100% + 26px);
    margin-bottom: -375px;
  }

  @media ${deviceBreakPoints.tablet} {
    margin-bottom: -307px;
  }

  @media ${deviceBreakPoints.mobile} {
    margin-bottom: -227px;
  }
`;

const ColImage = styled(Col)`
  position: relative;
`;

const query = graphql`
  query {
    data: allTechnologyPageYaml {
      edges {
        node {
          header {
            title
            caption
            description
            comparisonLists {
              swapbox {
                title
                properties
              }
              otherCryptoATM {
                title
                properties
              }
            }
          }
        }
      }
    }
  }
`;

export default TechnologyPageHeader;
